<script>
  import Router from './lib/router/Router.svelte'
  import routes from './routes'

  import { redirectTo } from './lib/router/Navigate'

  import ToastContainer from './lib/toasts/ToastContainer.svelte'
  const redirect = window.sessionStorage.getItem('redirect')

  if (redirect !== null && redirect !== window.location.pathname) {
    window.sessionStorage.removeItem('redirect')
    redirectTo(redirect.substring(12))
  }
</script>

<ToastContainer />
<Router {routes} />
